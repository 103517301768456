export const LANGUAGES = [
  { label: 'English', languageCode: 'en' },
  { label: 'German', languageCode: 'de' },
  { label: 'Spanish', languageCode: 'es' },
  { label: 'French', languageCode: 'fr' },
  { label: 'Portuguese', languageCode: 'pt' },
  { label: 'Russian', languageCode: 'ru' }
]

/* ==========================================================================
  HTTP Method Types
========================================================================== */
export const METHOD_TYPES = {
  get: 'get',
  post: 'post',
  put: 'put',
  delete: 'delete',
  patch: 'patch'
}

/* ==========================================================================
LocalStorage / Cookie data
========================================================================== */
export const TOKEN = 'authToken'
export const LANGUAGE = 'operator_language'
export const OPERATOR_ID = 'operator_id'
export const ROLE = 'operator_role'

/* ==========================================================================
Operator Roles
========================================================================== */
export const OPERATOR_ROLES = {
  operator: 'OPERATOR',
  operatorUser: 'OPERATOR_USER'
}

/* ==========================================================================
Loader types
========================================================================== */
export const LOADER = {
  BUTTON: 'button',
  CONTENT: 'content',
  TABLE_CELL: 'tableCell',
  PAGE: 'page'
}

/* ==========================================================================
Loader types
========================================================================== */
export const LOADER_TYPE = {
  SCALE: 'scale',
  PULSE: 'pulse'
}

/* ==========================================================================
  TOASTER / NOTIFICATION Types
========================================================================== */
export const TOASTER_TYPE = {
  success: 'success',
  error: 'error',
  warning: 'warning',
  info: 'info'
}

/* ==========================================================================
  Loader Types
========================================================================== */
export const LOADER_HANDLER_TYPES = {
  page: 'pageLoader',
  submit: 'submitButtonLoader',
  table: 'tableLoader'
}

/* ==========================================================================
  All the navigation route Paths
========================================================================== */
export const ROUTE_PATHS = {
  // ----single routes------------
  home: '/',
  crashGame: '/crash-game',
  plinkoGame: '/plinko-game',
  spaceDiceGame: '/space-dice-game',
}

/* ==========================================================================
  Game Ids
========================================================================== */
export const DEFAULT_GAME_ID = {
  CRASH: 1,
  PLINKO: 2,
  SPACE_DICE_GAME: 3,
}

/* ==========================================================================
  Extra Constants Variables
========================================================================== */
export const PAGINATION_LIMIT = 20
export const MIN_WITHDRAW_AMOUNT = 0.003
export const WITHDRAW_FEES = 0.000006
export const DEFAULT_PAGE_CALLS = 20
export const DEFAULT_TOP_BET_TYPE_LIMIT = 20
export const DEFAULT_MY_BETS_LIMIT = 20
export const DEFAULT_PRECISION_MONEY = 2
export const DEFAULT_AUTO_BET_COUNTER = 3
export const DEFAULT_BET_AMOUNT_SC = 1
export const DEFAULT_BET_AMOUNT_GC = 2500
export const TAB_SELECTION = Object.freeze({
  placed: Symbol('placed'),
  previous: Symbol('previous')
})
export const MIN_PAYOUT = {
  CRASH: 1.1
}

export const APPEND_TYPES = {
  ROUND_STOPPED: 'ROUND_STOPPED',
  ESCAPE: 'ESCAPE'
}

/* ==========================================================================
  Game Result
========================================================================== */
export const BET_RESULT = {
  WON: 'won',
  LOST: 'lost',
  CANCELLED: 'cancelled'
}

export const COIN_CURRENCY_ID = {
  GC: '1',
  SC: '2'
}

export const COIN_CURRENCY_CODES = {
  GC: 'GC',
  SC: 'SC'
}

/* ==========================================================================
  Wallet emit types
========================================================================== */
export const WALLET_EMIT_TYPES = {
  CREDIT: 'credit',
  DEBIT: 'debit',
  RESOLVE: 'resolve',
  DEFAULT: 'default'
}

export const PROVABLE_FAIR_TEXT = '"Provably fair" is a term that refers to a system or algorithm used in online gambling or gaming that can be mathematically verified to ensure that the results are fair and not manipulated in any way. It is a way for players to know that the game or gambling platform they are using is legitimate and not rigged against them.'

export const TOP_BET_TYPE = {
  BIGGEST_WIN: 'BIGGEST_WIN',
  HUGE_WIN: 'HUGE_WIN',
  MULTIPLIER: 'MULTIPLIER',
  GOLD_COINS: 'GC',
  SWEEPSTAKES_COINS: 'SC'
}

export const CRASH_BET_LISTING_TYPE_TABS = {
  ALL_BETS: 'ALL_BETS',
  MY_BETS: 'MY_BETS',
  TOP_BETS: 'TOP_BETS'
}

/* ==========================================================================
  PLINKO GAME CONSTANTS
========================================================================== */
export const PIN_IN_FIRST_LINE = 3

export const DEFAULT_PLINKO_PINS = 8

export const DEFAULT_PLINKO_RISK_LEVEL = 1

export const DEFAULT_PLINKO_AUTO_BETS = 10

export const PLINKO_FIXED_ODDS = {
  8: [
    [6.1, 2.2, 1.1, 1, 0.5, 1, 1.1, 2.2, 6.1],
    [12.8, 3.2, 1.5, 0.6, 0.4, 0.6, 1.5, 3.2, 12.8],
    [31, 5.3, 1.1, 0.3, 0.2, 0.3, 1.1, 5.3, 31],
    [41.7, 5.1, 1.1, 0.2, 0.1, 0.2, 1.1, 5.1, 41.7],
  ],
  9: [
    [6.4, 2.6, 1.5, 1, 0.7, 0.7, 1, 1.5, 2.6, 6.4],
    [18.7, 3.7, 1.7, 0.8, 0.6, 0.6, 0.8, 1.7, 3.7, 18.7],
    [52, 7.2, 2, 0.5, 0.2, 0.2, 0.5, 2, 7.2, 52],
    [50.2, 12.8, 1, 0.5, 0.1, 0.1, 0.5, 1, 12.8, 50.2],
  ],
  10: [
    [8.4, 3.2, 1.2, 1.1, 1, 0.6, 1, 1.1, 1.2, 3.2, 8.4],
    [28, 12, 1.5, 1, 0.6, 0.4, 0.6, 1, 1.5, 12, 28],
    [65, 21, 1.5, 0.5, 0.4, 0.2, 0.4, 0.5, 1.5, 21, 65],
    [98.4, 20, 1.4, 0.8, 0.2, 0.1, 0.2, 0.8, 1.4, 20, 98.4],
  ],
  11: [
    [9.8, 5, 1.6, 1.4, 1.1, 0.6, 0.6, 1.1, 1.4, 1.6, 5, 9.8],
    [36, 7.3, 3, 1.5, 0.8, 0.5, 0.5, 0.8, 1.5, 3, 7.3, 36],
    [156, 23, 5, 0.9, 0.3, 0.2, 0.2, 0.3, 0.9, 5, 23, 156],
    [169, 29.5, 4, 1.2, 0.2, 0.1, 0.1, 0.2, 1.2, 4, 29.5, 169],
  ],
  12: [
    [9, 5.2, 1.5, 1.2, 1.1, 1, 0.6, 1, 1.1, 1.2, 1.5, 5.2, 9],
    [45, 16, 4.4, 1.9, 0.8, 0.6, 0.5, 0.6, 0.8, 1.9, 4.4, 16, 45],
    [174, 25, 12, 1.5, 0.5, 0.2, 0.1, 0.2, 0.5, 1.5, 12, 25, 174],
    [220, 39, 7, 1.8, 0.6, 0.2, 0.1, 0.2, 0.6, 1.8, 7, 39, 220],
  ],
  13: [
    [14, 6.7, 3, 1.7, 1.1, 1, 0.7, 0.7, 1, 1.1, 1.7, 3, 6.7, 14],
    [55, 18, 7, 2.6, 1.3, 0.7, 0.4, 0.4, 0.7, 1.3, 2.6, 7, 18, 55],
    [274, 41, 11, 4.5, 1, 0.2, 0.1, 0.1, 0.2, 1, 4.5, 11, 41, 274],
    [369, 69, 19.6, 1.3, 0.7, 0.2, 0.1, 0.1, 0.2, 0.7, 1.3, 19.6, 69, 369],
  ],
  14: [
    [20, 8.4, 4, 1.8, 1.3, 1, 0.9, 0.6, 0.9, 1, 1.3, 1.8, 4, 8.4, 20],
    [77, 19, 8, 4.4, 1.6, 0.8, 0.6, 0.3, 0.6, 0.8, 1.6, 4.4, 8, 19, 77],
    [420, 56, 20, 6.3, 1.5, 0.3, 0.2, 0.1, 0.2, 0.3, 1.5, 6.3, 20, 56, 420],
    [526, 79, 26, 5, 1, 0.3, 0.2, 0.1, 0.2, 0.3, 1, 5, 26, 79, 526],
  ],
  15: [
    [31, 10, 6, 2.1, 1.5, 1.4, 0.9, 0.6, 0.6, 0.9, 1.4, 1.5, 2.1, 6, 10, 31],
    [100, 25, 13, 5, 3.1, 1.2, 0.5, 0.3, 0.3, 0.5, 1.2, 3.1, 5, 13, 25, 100],
    [606, 74, 30, 10, 2.8, 0.5, 0.2, 0.1, 0.1, 0.2, 0.5, 2.8, 10, 30, 74, 606],
    [1003, 125, 51, 8, 1, 0.5, 0.2, 0.1, 0.1, 0.2, 0.5, 1, 8, 51, 125, 1003],
  ],
  16: [
    [31, 11, 4, 1.8, 1.3, 1.2, 1.1, 1, 0.5, 1, 1.1, 1.2, 1.3, 1.8, 4, 11, 31],
    [140, 46, 11, 7, 3.4, 1.1, 1, 0.5, 0.3, 0.5, 1, 1.1, 3.4, 7, 11, 46, 140],
    [1000, 133, 30, 10, 3.5, 2, 0.3, 0.2, 0.1, 0.2, 0.3, 2, 3.5, 10, 30, 133, 1000],
    [1414, 223, 47, 10, 4, 0.9, 0.3, 0.2, 0.1, 0.2, 0.3, 0.9, 4, 10, 47, 223, 1414],
  ],
};
export const DEFAULT_PLINKO_LIGHTNING_MODE_BOARD = {
  betMultipliers: [{ position: [7, 8], multiplier: '2x' }, { position: [13, 10], multiplier: '15x' }, { position: [2, 2], multiplier: '40x' }],
  payouts: [1000, 155, 6.28, 1.09, 0.35, 0.16, 0, 0.02, 0, 0.01, 0, 0.07, 0.3, 1.4, 29.3, 157, 1000]
}

export const PLINKO_LIGHTNING_MODE_VARIABLE_ODDS = {
  1: [500, 125, 30, 5, 0.9, 0.3, 0, 0.1, 0, 0.1, 0, 0.3, 0.9, 5, 30, 125, 500],
  2: [1000, 155, 15, 3.59, 1.4, 0.69, 0, 0.07, 0, 0.04, 0, 0.17, 0.25, 0.46, 1.27, 8.43, 1000],
  3: [1000, 155, 6.28, 1.09, 0.35, 0.16, 0, 0.02, 0, 0.01, 0, 0.07, 0.3, 1.4, 29.3, 157, 1000],
  4: [1000, 6.39, 0.96, 0.32, 0.16, 0.09, 0, 0.02, 0, 0.03, 0, 0.61, 2.03, 7.42, 28.7, 152, 1000]
}

export const BOX_COLORS = {
  red: 'red',
  orange: 'orange',
  pink: 'pink',
  purple: 'purple',
  blue: 'blue',
  green: 'green'
}

export const BOX_COLORS_NUMBERS = {
  red: 1,
  orange: 2,
  pink: 3,
  purple: 5,
  blue: 7,
  green: 8
}

export const getColorOfWinBox = (box, totalLines = 8) => {
  const pegsInRows = Array.from({
    length: totalLines
  },
    (_, rowIndex) => rowIndex + PIN_IN_FIRST_LINE)

  let color = `${BOX_COLORS.green}`

  if (box < BOX_COLORS_NUMBERS.red || (box === (pegsInRows[totalLines - 1] - 1 - BOX_COLORS_NUMBERS.red))) {
    color = `${BOX_COLORS.red}`
  } else if (box < BOX_COLORS_NUMBERS.orange || (box === (pegsInRows[totalLines - 1] - 1 - BOX_COLORS_NUMBERS.orange))) {
    color = `${BOX_COLORS.orange}`
  } else if (box < BOX_COLORS_NUMBERS.pink || (box >= (pegsInRows[totalLines - 1] - 1 - BOX_COLORS_NUMBERS.pink))) {
    color = `${BOX_COLORS.pink}`
  } else if (box < BOX_COLORS_NUMBERS.purple || (box >= (pegsInRows[totalLines - 1] - 1 - BOX_COLORS_NUMBERS.purple))) {
    color = `${BOX_COLORS.purple}`
  } else if (box < BOX_COLORS_NUMBERS.blue || (box >= (pegsInRows[totalLines - 1] - 1 - BOX_COLORS_NUMBERS.blue))) {
    color = `${BOX_COLORS.blue}`
  }

  return color
}

/* ==========================================================================
  DICE GAME CONSTANTS
========================================================================== */
export const BET_MODES = {
  MANUAL: 'manual',
  AUTO: 'auto',
  STRATEGY: 'strategy'
}

export const MAX_BET_COUNT = {
  DICE_AUTO_BET: 5000
}

export const MAX_INCREASED_BY_PERCENTAGE = {
  DICE: 90
}

export const MIN_INCREASED_BY_PERCENTAGE = {
  DICE: 0.5
}

export const MAX_STOP_ON = {
  DICE: 1e6
}

export const DEFAULT_INCREASE_BY_PERCENTAGE = 5

export const defaultDiceStrategies = [
  {
    label: 'Martingale',
    isDefault: true,
    blocks: [
      {
        id: 'mart1',
        type: 'bets',
        on: {
          type: 'every',
          value: 1,
          betType: 'lose',
          profitType: 'balance'
        },
        do: {
          type: 'increaseByPercentage',
          value: 100
        }
      },
      {
        id: 'mart2',
        type: 'bets',
        on: {
          type: 'every',
          value: 1,
          betType: 'win'
        },
        do: {
          type: 'increaseByPercentage',
          value: 0
        }
      }
    ]
  },
  {
    label: 'Delayed Martingale',
    isDefault: true,
    blocks: [
      {
        id: 'delay1',
        type: 'bets',
        on: {
          type: 'streakGreaterThan',
          value: 3,
          betType: 'lose',
          profitType: 'balance'
        },
        do: {
          type: 'increaseByPercentage',
          value: 100
        }
      },
      {
        id: 'delay2',
        type: 'bets',
        on: {
          type: 'firstStreakOf',
          value: 3,
          betType: 'lose',
          profitType: 'balance'
        },
        do: {
          type: 'increaseByPercentage',
          value: 100
        }
      },
      {
        id: 'delay3',
        type: 'bets',
        on: {
          type: 'every',
          value: 1,
          betType: 'win',
          profitType: 'balance'
        },
        do: {
          type: 'resetAmount',
          value: 0
        }
      }
    ]
  },
  {
    label: 'Paroli',
    isDefault: true,
    blocks: [
      {
        id: 'paroli1',
        type: 'bets',
        on: {
          type: 'every',
          value: 1,
          betType: 'win',
          profitType: 'balance'
        },
        do: {
          type: 'increaseByPercentage',
          value: 100
        }
      },
      {
        id: 'paroli2',
        type: 'bets',
        on: {
          type: 'streakGreaterThan',
          value: 3,
          betType: 'win',
          profitType: 'balance'
        },
        do: {
          type: 'resetAmount',
          value: 0
        }
      },
      {
        id: 'paroli3',
        type: 'bets',
        on: {
          type: 'every',
          value: 1,
          betType: 'lose',
          profitType: 'balance'
        },
        do: {
          type: 'resetAmount',
          value: 0
        }
      }
    ]
  },
  {
    label: "D 'Alembert",
    isDefault: true,
    blocks: [
      {
        id: 'Alembert1',
        type: 'bets',
        on: {
          type: 'every',
          value: 1,
          betType: 'win',
          profitType: 'balance'
        },
        do: {
          type: 'addToAmount',
          value: 1e-8
        }
      },
      {
        id: 'Alembert2',
        type: 'bets',
        on: {
          type: 'every',
          value: 1,
          betType: 'lose',
          profitType: 'balance'
        },
        do: {
          type: 'subtractFromAmount',
          value: 1e-8
        }
      }
    ]
  }
]

/* ==========================================================================
  Zoot CONSTANTS
========================================================================== */

export const ZOOT_CURRENCY_TYPES = Object.freeze({
  GOLD: { name: 'gold', balanceName: 'goldBalance', code: 'GC' },
  SWEEPS: { name: 'sweeps', balanceName: 'sweepsBalance', code: 'SC' }
})

export const ZOOT_BET_INCREMENT_DECREMENT = Object.freeze({
  INCREMENT: 0.1,
  DECREMENT: 0.1
})
